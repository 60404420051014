import { ru } from 'vuetify/src/locale'

export default async () => ({
  $vuetify: {
    ...ru
  },

  accept_attempt: {
    one: 'Принять попытку'
  },

  accounts: {
    one: 'Аккаунт',
    two: 'Аккаунта',
    many: 'Аккаунтов',
    other: 'Аккаунты',
    genitive_one: 'Аккаунта',
    genitive: 'Аккаунтов',
    dative_one: 'Аккаунту',
    dative: 'Аккаунтам',
    accusative_one: 'Аккаунт',
    accusative: 'Аккаунты',
    prepositional_one: 'Аккаунте',
    prepositional: 'Аккаунтах'
  },

  add: {
    one: 'Добавить'
  },

  address: {
    one: 'Адрес регистрации'
  },

  address_of_a_person: {
    one: 'Адрес регистрации'
  },

  again: {
    one: 'Ещё раз'
  },

  all: {
    one: 'Все',
    other: 'Все'
  },

  anonymous_first_question: {
    one: 'Впервые у нас?'
  },

  anonymous_forgot_password: {
    one: 'Забыли пароль?'
  },

  anonymous_register: {
    one: 'Зарегистрироваться'
  },

  anonymous_reset_password: {
    one: 'Сбросить пароль'
  },

  anonymous_sign_in: {
    one: 'Войти в систему'
  },

  anonymous_sign_out: {
    one: 'Выйти из системы'
  },

  anonymous_your_email: {
    one: 'Ваш адрес электронной почты'
  },

  anonymous_your_login_or_email: {
    one: 'Ваш логин или адрес электронной почты'
  },

  answers: {
    one: 'Ответ',
    two: 'Ответа',
    many: 'Ответов',
    other: 'Ответы',
    genitive_one: 'Ответа',
    genitive: 'Ответов',
    dative_one: 'Ответу',
    dative: 'Ответам',
    accusative_one: 'Ответ',
    accusative: 'Ответы',
    prepositional_one: 'Ответе',
    prepositional: 'Ответах'
  },

  attach_files: {
    one: 'Прикрепить файл',
    other: 'Прикрепить файлы'
  },

  attempts: {
    one: 'Попытка',
    two: 'Попытки',
    many: 'Попыток',
    other: 'Попытки',
    genitive_one: 'Попытки',
    genitive: 'Попыток',
    dative_one: 'Попытке',
    dative: 'Попыткам',
    accusative_one: 'Попытку',
    accusative: 'Попытки',
    prepositional_one: 'Попытке',
    prepositional: 'Попытках'
  },

  attempts_left_total: {
    one: 'Осталось {count} из {all} {unit} на весь период обучения'
  },

  attempts_left_today: {
    one: 'Осталось {count} из {all} {unit} на сегодня'
  },

  available: {
    one: 'Доступно'
  },

  back: {
    one: 'Назад'
  },

  bank_address: {
    one: 'Адрес банка'
  },

  bank_name: {
    one: 'Название банка'
  },

  bik: {
    one: 'БИК'
  },

  cancel: {
    one: 'Отменить'
  },

  certificate_full_name: {
    one: 'ФИО в документе'
  },

  certificates: {
    one: 'Итоговый документ',
    two: 'Итоговых документа',
    many: 'Итоговых документов',
    other: 'Итоговые документы',
    genitive_one: 'Итогового документа',
    genitive: 'Итоговых документов',
    dative_one: 'Итоговому документу',
    dative: 'Итоговым документам',
    accusative_one: 'Итоговый документ',
    accusative: 'Итоговые документы',
    prepositional_one: 'Итоговом документе',
    prepositional: 'Итоговых документах'
  },

  citizenship: {
    one: 'Гражданство',
    two: 'Гражданства',
    many: 'Гражданств',
    other: 'Гражданства',
    genitive_one: 'Гражданств',
    genitive: 'Гражданства',
    dative_one: 'Гражданству',
    dative: 'Гражданствам',
    accusative_one: 'Гражданства',
    accusative: 'Гражданство',
    prepositional_one: 'Гражданстве',
    prepositional: 'Гражданствах'
  },

  close: {
    one: 'Закрыть'
  },

  complete: {
    one: 'Завершить'
  },

  complete_training: {
    one: 'Завершить обучение'
  },

  completed_at: {
    one: 'Завершено'
  },

  continue: {
    one: 'Продолжить'
  },

  continue_training: {
    one: 'Продолжить обучение'
  },

  contractors: {
    one: 'Контрагент',
    two: 'Контрагента',
    many: 'Контрагентов',
    other: 'Контрагенты',
    genitive_one: 'Контрагента',
    genitive: 'Контрагентов',
    dative_one: 'Контрагенту',
    dative: 'Контрагентам',
    accusative_one: 'Контрагента',
    accusative: 'Контрагентов',
    prepositional_one: 'Контрагенте',
    prepositional: 'Контрагентах'
  },

  courses: {
    one: 'Курс обучения',
    two: 'Курса обучения',
    many: 'Курсов обучения',
    other: 'Курсы обучения',
    genitive_one: 'Курса обучения',
    genitive: 'Курсов обучения',
    dative_one: 'Курсу обучения',
    dative: 'Курсам обучения',
    accusative_one: 'Курс обучения',
    accusative: 'Курсы обучения',
    prepositional_one: 'Курсе обучения',
    prepositional: 'Курсах обучения'
  },

  created: {
    one: 'Создано',
    other: 'Созданы'
  },

  delete: {
    one: 'Удалить'
  },

  descriptions: {
    one: 'Описание',
    two: 'Описания',
    many: 'Описаний',
    other: 'Описания',
    genitive_one: 'Описания',
    genitive: 'Описаний',
    dative_one: 'Описанию',
    dative: 'Описаниям',
    accusative_one: 'Описание',
    accusative: 'Описания',
    prepositional_one: 'Описании',
    prepositional: 'Описаниях'
  },

  director: {
    one: 'Руководитель',
    two: 'Руководителя',
    many: 'Руководителей',
    other: 'Руководители',
    genitive_one: 'Руководителя',
    genitive: 'Руководителей',
    dative_one: 'Руководителю',
    dative: 'Руководителям',
    accusative_one: 'Руководителя',
    accusative: 'Руководителей',
    prepositional_one: 'Руководителе',
    prepositional: 'Руководителях'
  },

  dob: {
    one: 'Дата рождения',
    two: 'Даты рождения',
    many: 'Дат рождения',
    other: 'Даты рождения',
    genitive_one: 'Даты рождения',
    genitive: 'Дат рождения',
    dative_one: 'Дате рождения',
    dative: 'Датам рождения',
    accusative_one: 'Дату рождения',
    accusative: 'Даты рождения',
    prepositional_one: 'Дате рождения',
    prepositional: 'Датах рождения'
  },

  documents: {
    one: 'Документ',
    two: 'Документа',
    many: 'Документов',
    other: 'Документы',
    genitive_one: 'Документа',
    genitive: 'Документов',
    dative_one: 'Документу',
    dative: 'Документам',
    accusative_one: 'Документ',
    accusative: 'Документы',
    prepositional_one: 'Документе',
    prepositional: 'Документах'
  },

  edit: {
    one: 'Редактировать'
  },

  education_level: {
    one: 'Уровень образования'
  },

  educational_institution: {
    one: 'Учебное заведение'
  },

  educational_institution_level: {
    one: 'Уровень учебного заведения'
  },

  emails: {
    one: 'Адрес электронной почты',
    two: 'Адреса электронной почты',
    many: 'Адресов электронной почты',
    other: 'Адреса электронной почты',
    genitive_one: 'Адреса электронной почты',
    genitive: 'Адресов электронной почты',
    dative_one: 'Адресу электронной почты',
    dative: 'Адресам электронной почты',
    accusative_one: 'Адрес электронной почты',
    accusative: 'Адресы электронной почты',
    prepositional_one: 'Адресе электронной почты',
    prepositional: 'Адресах электронной почты'
  },

  employers: {
    one: 'Место работы',
    two: 'Места рабты',
    many: 'Мест работы',
    other: 'Места работы',
    genitive_one: 'Места работы',
    genitive: 'Места работы',
    dative_one: 'Месту работы',
    dative: 'Местам работы',
    accusative_one: 'Место работы',
    accusative: 'Места работы',
    prepositional_one: 'Месте работы',
    prepositional: 'Местах работы'
  },

  essays: {
    one: 'Практическое задание',
    two: 'Практических задания',
    many: 'Практических заданий',
    other: 'Практические задания',
    genitive_one: 'Практического задания',
    genitive: 'Практических заданий',
    dative_one: 'Практическому заданию',
    dative: 'Практическим заданиям',
    accusative_one: 'Практическое задание',
    accusative: 'Практические задания',
    prepositional_one: 'Практическом задании',
    prepositional: 'Практических заданиях'
  },

  exercise: {
    one: 'Тренировочный',
    other: 'Тренировочные'
  },

  files: {
    one: 'Файл',
    two: 'Файла',
    many: 'Файлов',
    other: 'Файлы',
    genitive_one: 'Файла',
    genitive: 'Файлов',
    dative_one: 'Файлу',
    dative: 'Файлам',
    accusative_one: 'Файл',
    accusative: 'Файлы',
    prepositional_one: 'Файле',
    prepositional: 'Файлах'
  },

  final_test_possible_complete_only: {
    one: 'Последний обязательный тест или практическое задание можно выполнить'
  },

  final_test_possible_was_complete_only: {
    one: 'Последний обязательный тест или практическое задание можно было выполнить'
  },

  final_test_possible_will_be_complete_only: {
    one: 'Последний обязательный тест или практическое задание можно выполнить'
  },

  form_filed_desc_free_form: {
    one: 'в свободной форме'
  },

  from: {
    one: 'с'
  },

  full_name_person: {
    one: 'ФИО'
  },

  gender: {
    one: 'Пол'
  },

  gender_female: {
    one: 'Женский'
  },

  gender_male: {
    one: 'Мужской'
  },

  general_questions: {
    one: 'Общий вопрос',
    two: 'Общих вопроса',
    many: 'Общих вопросов',
    other: 'Общие вопросы',
    genitive_one: 'Общего вопроса',
    genitive: 'Общих вопросов',
    dative_one: 'Общему вопросу',
    dative: 'Общим вопросам',
    accusative_one: 'Общий вопрос',
    accusative: 'Общие вопросы',
    prepositional_one: 'Общем вопросе',
    prepositional: 'Общих вопросах'
  },

  generate_new_password: {
    one: 'Сгенерировать новый пароль'
  },

  groups: {
    one: 'Группа по курсу',
    two: 'Группы по курсу',
    many: 'Групп по курсу',
    other: 'Группы по курсу',
    genitive_one: 'Группы по курсу',
    genitive: 'Групп по курсу',
    dative_one: 'Группе по курсу',
    dative: 'Группам по курсу',
    accusative_one: 'Группу по курсу',
    accusative: 'Группы по курсу',
    prepositional_one: 'Группе по курсу',
    prepositional: 'Группах по курсу'
  },

  help_center: {
    one: 'Справочный центр'
  },

  help_not_found: {
    one: 'Справочный материал не найден'
  },

  in_accusative_case: {
    one: 'в винительном падеже'
  },

  in_dative_case: {
    one: 'в дательном падеже'
  },

  in_genitive_case: {
    one: 'в родительном падеже'
  },

  information_doc_education_of_the_user: {
    one: 'Сведения о документах об образовании пользователя'
  },

  information_education: {
    one: 'Сведения об образовании'
  },

  inn: {
    one: 'ИНН'
  },

  interface_language: {
    one: 'Язык интерфейса'
  },

  kpp: {
    one: 'КПП'
  },

  ks: {
    one: 'Корреспондентский счёт'
  },

  last_attempt_left_today: {
    one: 'Осталась последняя попытка на сегодня'
  },

  last_attempt_left_total: {
    one: 'Осталась последняя попытка'
  },

  lectures: {
    one: 'Лекция',
    two: 'Лекции',
    many: 'Лекций',
    other: 'Лекции',
    genitive_one: 'Лекции',
    genitive: 'Лекций',
    dative_one: 'Лекции',
    dative: 'Лекциям',
    accusative_one: 'Лекцию',
    accusative: 'Лекции',
    prepositional_one: 'Лекции',
    prepositional: 'Лекциях'
  },

  left: {
    one: 'Осталось'
  },

  lessons: {
    one: 'Урок',
    two: 'Урока',
    many: 'Уроков',
    other: 'Уроки',
    genitive_one: 'Урока',
    genitive: 'Уроков',
    dative_one: 'Уроку',
    dative: 'Урокам',
    accusative_one: 'Урок',
    accusative: 'Уроки',
    prepositional_one: 'Уроке',
    prepositional: 'Уроках'
  },

  list_lessons: {
    one: 'Список уроков',
    two: 'Списка уроков',
    many: 'Списков уроков',
    other: 'Списки уроков',
    genitive_one: 'Списка уроков',
    genitive: 'Списков уроков',
    dative_one: 'Списку уроков',
    dative: 'Спискам уроков',
    accusative_one: 'Список уроков',
    accusative: 'Списки уроков',
    prepositional_one: 'Списке уроков',
    prepositional: 'Списках уроков'
  },

  loading_please_wait: {
    one: 'Идёт загрузка'
  },

  locality: {
    one: 'Город регистрации'
  },

  locality_of_a_person: {
    one: 'Город регистрации или проживания'
  },

  ls: {
    one: 'Лицевой счёт'
  },

  managers: {
    one: 'Менеджер',
    two: 'Менеджера',
    many: 'Менеджеров',
    other: 'Менеджеры',
    genitive_one: 'Менеджера',
    genitive: 'Менеджеров',
    dative_one: 'Менеджеру',
    dative: 'Менеджерам',
    accusative_one: 'Менеджера',
    accusative: 'Менеджеров',
    prepositional_one: 'Менеджере',
    prepositional: 'Менеджерах'
  },

  marks: {
    one: 'Оценка',
    two: 'Оценки',
    many: 'Оценок',
    other: 'Оценки',
    genitive_one: 'Оценки',
    genitive: 'Оценок',
    dative_one: 'Оценке',
    dative: 'Оценкам',
    accusative_one: 'Оценку',
    accusative: 'Оценки',
    prepositional_one: 'Оценке',
    prepositional: 'Оценках'
  },

  meeting_status_accepted: {
    one: 'Запланировано'
  },

  meeting_status_canceled: {
    one: 'Отменено'
  },

  meeting_status_completed: {
    one: 'Проведено'
  },

  meeting_status_pending: {
    one: 'Не обработано'
  },

  meeting_status_started: {
    one: 'Проводится'
  },

  meetings: {
    one: 'Мероприятие',
    two: 'Мероприятия',
    many: 'Мероприятий',
    other: 'Мероприятия',
    genitive_one: 'Мероприятия',
    genitive: 'Мероприятий',
    dative_one: 'Мероприятию',
    dative: 'Мероприятиям',
    accusative_one: 'Мероприятие',
    accusative: 'Мероприятия',
    prepositional_one: 'Мероприятии',
    prepositional: 'Мероприятиях'
  },

  messages: {
    one: 'Сообщение',
    two: 'Сообщения',
    many: 'Сообщений',
    other: 'Сообщения',
    genitive_one: 'Сообщения',
    genitive: 'Сообщений',
    dative_one: 'Сообщению',
    dative: 'Сообщениям',
    accusative_one: 'Сообщение',
    accusative: 'Сообщения',
    prepositional_one: 'Сообщении',
    prepositional: 'Сообщениях'
  },

  messages_empty_message: {
    one: 'У вас пока нет сообщений. Если у вас возникли сложности с обучением или работой системы'
  },

  messages_general_question: {
    one: 'Написать общий вопрос'
  },

  minutes: {
    one: 'минута',
    two: 'минуты',
    many: 'минут',
    other: 'минуты',
    genitive_one: 'минуты',
    genitive: 'минут',
    dative_one: 'минуте',
    dative: 'минутам',
    accusative_one: 'минуту',
    accusative_two: 'минуты',
    accusative_many: 'минут',
    accusative: 'минуты',
    prepositional_one: 'минуте',
    prepositional: 'минутах'
  },

  new_attempts: {
    one: 'Новая попытка'
  },

  next_lesson: {
    one: 'Следующий урок'
  },

  next_question: {
    one: 'Следующий вопрос'
  },

  no: {
    one: 'нет'
  },

  no_attempts_left_today: {
    one: 'У вас больше не осталось попыток выполнить сегодня это задание. Попробуйте завтра'
  },

  no_attempts_left_total: {
    one: 'У вас больше не осталось попыток выполнить это задание'
  },

  no_training: {
    one: 'Обучение не разрешено'
  },

  not_specified: {
    one: 'Не указано',
    other: 'Не указаны'
  },

  numbers: {
    one: 'Номер',
    two: 'Номера',
    many: 'Номеров',
    other: 'Номера',
    genitive_one: 'Номера',
    genitive: 'Номеров',
    dative_one: 'Номеру',
    dative: 'Номерам',
    accusative_one: 'Номер',
    accusative: 'Номера',
    prepositional_one: 'Номере',
    prepositional: 'Номерах'
  },

  ogrn: {
    one: 'ОГРН/ОГРНИП'
  },

  okpo: {
    one: 'ОКПО'
  },

  oktmo: {
    one: 'ОКТМО'
  },

  okved: {
    one: 'ОКВЭД'
  },

  online_shop: {
    one: 'Витрина курсов',
    other: 'Витрины курсов',
    genitive_one: 'Витрины курсов',
    genitive: 'Витрин курсов',
    dative_one: 'Витрине курсов',
    dative: 'Витринам курсов',
    accusative_one: 'Витрину курсов',
    accusative: 'Витрины курсов',
    prepositional_one: 'Витрине курсов',
    prepositional: 'Витринах курсов'
  },

  optional: {
    one: 'Необязательный',
    other: 'Необязательные'
  },

  order_management: {
    one: 'Управление заказами'
  },

  out_of: {
    one: 'из'
  },

  passwords: {
    one: 'Пароль',
    two: 'Пароля',
    many: 'Паролей',
    other: 'Пароли',
    genitive_one: 'Пароля',
    genitive: 'Паролей',
    dative_one: 'Паролю',
    dative: 'Паролям',
    accusative_one: 'Пароль',
    accusative: 'Пароли',
    prepositional_one: 'Пароле',
    prepositional: 'Паролях'
  },

  periods: {
    one: 'Период',
    two: 'Периода',
    many: 'Периодов',
    other: 'Периоды',
    genitive_one: 'Периода',
    genitive: 'Периодов',
    dative_one: 'Периоду',
    dative: 'Периодам',
    accusative_one: 'Период',
    accusative: 'Периоды',
    prepositional_one: 'Периоде',
    prepositional: 'Периодах'
  },

  personal_data_processing_policy: {
    one: 'Политика обработки персональных данных'
  },

  phones: {
    one: 'Телефон',
    two: 'Телефона',
    many: 'Телефонов',
    other: 'Телефоны',
    genitive_one: 'Телефона',
    genitive: 'Телефонов',
    dative_one: 'Телефону',
    dative: 'Телефонам',
    accusative_one: 'Телефон',
    accusative: 'Телефоны',
    prepositional_one: 'Телефоне',
    prepositional: 'Телефонах'
  },

  physical_address_of_a_person: {
    one: 'Фактический адрес проживания'
  },

  please_enter_answer: {
    one: 'Выберите вариант (ы) ответа на вопрос!'
  },

  points_count_of: {
    one: 'Набрано {count} из {all} необходимых баллов'
  },

  positive_score: {
    one: 'Проходной балл, не менее'
  },

  positive_score_of: {
    one: 'Проходной балл, не менее: {count} из {all}'
  },

  postal_address_of_a_person: {
    one: 'Почтовый адрес'
  },

  previous_lesson: {
    one: 'Предыдущий урок'
  },

  previous_question: {
    one: 'Предыдущий вопрос'
  },

  print: {
    one: 'Напечатать'
  },

  profiles: {
    one: 'Профиль',
    two: 'Профиля',
    many: 'Профилей',
    other: 'Профили',
    genitive_one: 'Профиля',
    genitive: 'Профилей',
    dative_one: 'Профилю',
    dative: 'Профилям',
    accusative_one: 'Профиль',
    accusative: 'Профили',
    prepositional_one: 'Профиле',
    prepositional: 'Профилях'
  },

  questions: {
    one: 'Вопрос',
    two: 'Вопроса',
    many: 'Вопросов',
    other: 'Вопросы',
    genitive_one: 'Вопроса',
    genitive: 'Вопросов',
    dative_one: 'Вопросу',
    dative: 'Вопросам',
    accusative_one: 'Вопрос',
    accusative: 'Вопросы',
    prepositional_one: 'Вопросе',
    prepositional: 'Вопросах'
  },

  question_may_have_several_answers: {
    one: 'Этот вопрос может иметь несколько вариантов ответа'
  },

  question_requires_to_put_the_answer_options_in_the_correct_order: {
    one: 'В этом вопросе требуется расположить варианты ответа в правильном порядке'
  },

  quizzes: {
    one: 'Тест',
    two: 'Теста',
    many: 'Тестов',
    other: 'Тесты',
    genitive_one: 'Теста',
    genitive: 'Тестов',
    dative_one: 'Тесту',
    dative: 'Тестам',
    accusative_one: 'Тест',
    accusative: 'Тесты',
    prepositional_one: 'Тесте',
    prepositional: 'Тестах'
  },

  questions_and_variants_of_answers: {
    one: 'Вопросы и варианты ответов'
  },

  remove: {
    one: 'Убрать'
  },

  reply: {
    one: 'Ответить'
  },

  results: {
    one: 'Результат',
    two: 'Результата',
    many: 'Результатов',
    other: 'Результаты',
    genitive_one: 'Результата',
    genitive: 'Результатов',
    dative_one: 'Результату',
    dative: 'Результатам',
    accusative_one: 'Результат',
    accusative: 'Результаты',
    prepositional_one: 'Результате',
    prepositional: 'Результатах'
  },

  required: {
    one: 'Обязательный',
    other: 'Обязательные'
  },

  rs: {
    one: 'Расчётный счёт'
  },

  save: {
    one: 'Сохранить'
  },

  scores_for_question: {
    one: 'Набрано за вопрос'
  },

  scorm: {
    one: 'SCORM'
  },

  search: {
    one: 'Искать'
  },

  send: {
    one: 'Отправить'
  },

  series: {
    one: 'Серия'
  },

  services: {
    one: 'Сервис',
    two: 'Сервисы',
    many: 'Сервисов',
    other: 'Сервисы',
    genitive_one: 'Сервиса',
    genitive: 'Сервисов',
    dative_one: 'Сервису',
    dative: 'Сервисам',
    accusative_one: 'Сервис',
    accusative: 'Сервисы',
    prepositional_one: 'Сервисе',
    prepositional: 'Сервисах'
  },

  session_ends_at: {
    one: 'Окончание обучения'
  },

  session_starts_at: {
    one: 'Начало обучения'
  },

  session_status_accepted: {
    one: 'Обучение успешно завершено'
  },

  session_status_completed: {
    one: 'Обучение завершено'
  },

  session_status_evaluated: {
    one: 'Обучение выполнено неверно'
  },

  session_status_pending: {
    one: 'Обучение ещё не начато'
  },

  session_status_rejected: {
    one: 'Обучение выполнено неверно'
  },

  session_status_skipped: {
    one: 'Обучение пропущено'
  },

  session_status_started: {
    one: 'Идёт обучение'
  },

  sessions: {
    one: 'Программа обучения',
    two: 'Программы обучения',
    many: 'Программ обучения',
    other: 'Программы обучения',
    genitive_one: 'Программы обучения',
    genitive: 'Программ обучения',
    dative_one: 'Программе обучения',
    dative: 'Программам обучения',
    accusative_one: 'Программу обучения',
    accusative: 'Программы обучения',
    prepositional_one: 'Программе обучения',
    prepositional: 'Программах обучения'
  },

  show_in_fullscreen: {
    one: 'Развернуть на весь экран'
  },

  signed_at: {
    one: 'Дата подписания'
  },

  skip: {
    one: 'Пропустить'
  },

  slides: {
    one: 'Слайд',
    two: 'Слайда',
    many: 'Слайдов',
    other: 'Слайды',
    genitive_one: 'Слайда',
    genitive: 'Слайдам',
    dative_one: 'Слайду',
    dative: 'Слайдам',
    accusative_one: 'Слайд',
    accusative: 'Слайды',
    prepositional_one: 'Слайде',
    prepositional: 'Слайдах'
  },

  snils: {
    one: 'СНИЛС'
  },

  speakers: {
    one: 'Ведущий',
    two: 'Ведущих',
    many: 'Ведущих',
    other: 'Ведущие',
    genitive_one: 'Ведущего',
    genitive: 'Ведущих',
    dative_one: 'Ведущему',
    dative: 'Ведущим',
    accusative_one: 'Ведущего',
    accusative: 'Ведущих',
    prepositional_one: 'Ведущем',
    prepositional: 'Ведущих'
  },

  start_training: {
    one: 'Начать обучение'
  },

  start: {
    one: 'Начать'
  },

  started_at: {
    one: 'Начато'
  },

  statuses: {
    one: 'Статус',
    two: 'Статуса',
    many: 'Статусов',
    other: 'Статусы',
    genitive_one: 'Статуса',
    genitive: 'Статусов',
    dative_one: 'Статусу',
    dative: 'Статусам',
    accusative_one: 'Статус',
    accusative: 'Статусы',
    prepositional_one: 'Статусе',
    prepositional: 'Статусах'
  },

  step_is_available_at: {
    one: 'Это задание можно выполнить'
  },

  step_status_accepted: {
    one: 'Выполнено верно'
  },

  step_status_completed: {
    one: 'На проверке'
  },

  step_status_evaluated: {
    one: 'Выполнено неверно'
  },

  step_status_pending: {
    one: 'Выполнение ещё не начато'
  },

  step_status_rejected: {
    one: 'Выполнено неверно'
  },

  step_status_skipped: {
    one: 'Обучение пропущено'
  },

  step_status_started: {
    one: 'Идёт обучение'
  },

  step_was_available_at: {
    one: 'Это задание можно было выполнить'
  },

  step_will_be_available_at: {
    one: 'Это задание можно будет выполнить'
  },

  students_record_book: {
    one: 'История обучения'
  },

  subjects: {
    one: 'Тема',
    two: 'Темы',
    many: 'Тем',
    other: 'Темы',
    genitive_one: 'Темы',
    genitive: 'Тем',
    dative_one: 'Теме',
    dative: 'Темам',
    accusative_one: 'Тему',
    accusative: 'Темы',
    prepositional_one: 'Теме',
    prepositional: 'Темах'
  },

  submit: {
    one: 'Готово'
  },

  summative_lessons: {
    one: 'Итоговый (оценочный) урок',
    two: 'Итоговых (оценочных) урока',
    many: 'Итоговых (оценочных) уроков',
    other: 'Итоговые (оценочные) уроки',
    genitive_one: 'Итогового (оценочного) урока',
    genitive: 'Итоговых (оценочных) уроков',
    dative_one: 'Итоговому (оценочному) уроку',
    dative: 'Итоговым (оценочным) урокам',
    accusative_one: 'Итоговый (оценочный) урок',
    accusative: 'Итоговые (оценочные) уроки',
    prepositional_one: 'Итоговом (оценочном) уроке',
    prepositional: 'Итоговых (оценочных) уроках'
  },

  surveys: {
    one: 'Опрос',
    two: 'Опроса',
    many: 'Опросов',
    other: 'Опросы',
    genitive_one: 'Опроса',
    genitive: 'Опросов',
    dative_one: 'Опросу',
    dative: 'Опросам',
    accusative_one: 'Опрос',
    accusative: 'Опросы',
    prepositional_one: 'Опросе',
    prepositional: 'Опросах'
  },

  tasks: {
    one: 'Задание',
    two: 'Задания',
    many: 'Заданий',
    other: 'Задания',
    genitive_one: 'Задания',
    genitive: 'Заданий',
    dative_one: 'Заданию',
    dative: 'Заданиям',
    accusative_one: 'Задание',
    accusative: 'Задания',
    prepositional_one: 'Задании',
    prepositional: 'Заданиях'
  },

  teachers: {
    one: 'Преподаватель',
    two: 'Преподавателя',
    many: 'Преподавателей',
    other: 'Преподаватели',
    genitive_one: 'Преподавателя',
    genitive: 'Преподавателей',
    dative_one: 'Преподавателю',
    dative: 'Преподавателям',
    accusative_one: 'Преподавателя',
    accusative: 'Преподавателей',
    prepositional_one: 'Преподавателе',
    prepositional: 'Преподавателях'
  },

  textbooks: {
    one: 'Учебник',
    two: 'Учебника',
    many: 'Учебников',
    other: 'Учебники',
    genitive_one: 'Учебника',
    genitive: 'Учебников',
    dative_one: 'Учебнику',
    dative: 'Учебникам',
    accusative_one: 'Учебник',
    accusative: 'Учебники',
    prepositional_one: 'Учебнике',
    prepositional: 'Учебниках'
  },

  time_to_attempt: {
    one: 'Время выполнения попытки'
  },

  to: {
    one: 'по'
  },

  training_completed_at: {
    one: 'Обучение завершено'
  },

  training_possible_only: {
    one: 'Обучение доступно'
  },

  training_possible_was_only: {
    one: 'Обучение было доступно'
  },

  training_possible_will_be_only: {
    one: 'Обучение будет доступно'
  },

  training_started_at: {
    one: 'Обучение начато'
  },

  types: {
    one: 'Тип',
    two: 'Типа',
    many: 'Типов',
    other: 'Типы'
  },

  videos: {
    one: 'Видеоролик',
    two: 'Видеоролика',
    many: 'Видеороликов',
    other: 'Видеоролики',
    genitive_one: 'Видеоролика',
    genitive: 'Видеороликов',
    dative_one: 'Видеоролику',
    dative: 'Видеороликам',
    accusative_one: 'Видеоролик',
    accusative: 'Видеоролики',
    prepositional_one: 'Видеоролике',
    prepositional: 'Видеороликах'
  },

  update: {
    one: 'Обновить'
  },

  user_profile_passport_info: {
    one: 'Паспортные данные',
    other: 'Паспортные данные',
    genitive_one: 'Паспортных данных',
    genitive: 'Паспортных данных',
    dative_one: 'Паспортным данным',
    dative: 'Паспортным данным',
    accusative_one: 'Паспортные данные',
    accusative: 'Паспортные данные',
    prepositional_one: 'Паспортных данных',
    prepositional: 'Паспортных данных'
  },

  user_profile_passport_info_placeholder: {
    one: 'Серия, номер, кем и когда выдан'
  },

  user_profile_position: {
    one: 'Должность',
    two: 'Должности',
    many: 'Должностей',
    other: 'Должности',
    genitive_one: 'Должности',
    genitive: 'Должностей',
    dative_one: 'Должности',
    dative: 'Должностям',
    accusative_one: 'Должность',
    accusative: 'Должности',
    prepositional_one: 'Должности',
    prepositional: 'Должностях'
  },

  usernames: {
    one: 'Логин',
    two: 'Логина',
    many: 'Логинов',
    other: 'Логины',
    genitive_one: 'Логина',
    genitive: 'Логинов',
    dative_one: 'Логину',
    dative: 'Логинам',
    accusative_one: 'Логин',
    accusative: 'Логины',
    prepositional_one: 'Логине',
    prepositional: 'Логинах'
  },

  write_to_us: {
    one: 'напишите нам'
  },

  yes: {
    one: 'да'
  },

  you_get_maximum_of_100_scores: {
    one: 'Вы получаете максимум 100 баллов за один ответ'
  },

  $app: {
    loading: 'Загрузка...',
    expand_list_of_lessons: 'Раскрыть список уроков',
    no_lessons: 'Уроков нет',
    exit: 'Выход',
    no_data: 'Нет данных',
    upload_photo: 'Загрузить фото',
    delete_photo: 'Удалить фото',
    page_not_found: 'Страница не найдена',
    nothing_selected: 'Ничего не выбрано',

    menuItem: {
      groups: 'Группы',
      library: 'Библиотека'
    },

    audio_recorder: {
      title: 'Запись аудио',
      start: 'Начать запись',
      stop: 'Остановить запись',
      progress: 'Говорите...',
      error: 'Не удалось найти микрофон'
    },

    action: {
      one: 'Действие'
    },

    all_fields_required: 'Заполните все поля',

    ask_question: {
      one: 'Задать вопрос'
    },

    basic_information: 'Основная информация',

    changes_saved: 'Изменения сохранены!',

    contact_information: 'Контактная информация',

    continue_survey: 'Продолжить опрос',

    completed: 'Пройдено',

    chat: {
      delete_confirm: 'Вы действительно хотите удалить этот чат?',
      select_title: 'Выберите чат или'
    },

    chat_create: {
      title: 'Новое сообщение',
      filesize_msg: 'К сообщению можно прикрепить один файл, размером не более {n} Мб',
      action_title: 'Написать новое сообщение'
    },

    choose_answer_on_scale: 'Выберите ответ на шкале',

    choose_one_answer: {
      one: 'Выберите один вариант ответа'
    },

    date_completion: {
      one: 'Дата выполнения'
    },

    default_message_text: 'Здравствуйте, вопрос по уроку',

    education: 'Образование',

    final_lesson: {
      one: 'Итоговый (оценочный) урок'
    },

    full_name: {
      accusative: 'ФИО в винительном падеже',
      dative: 'ФИО в дательном падеже',
      genitive: 'ФИО в родительном падеже',
      latin: 'ФИО латиницей',
      respective: 'ФИО: падежные формы'
    },

    go_management: 'Перейти в режим управления заказами',

    hide: 'Скрыть',

    lesson_status: {
      one: 'Статус урока'
    },

    lesson_status_accepted: 'Принято',

    lesson_status_completed: 'На проверке',

    lesson_status_rejected: 'Выполнено неверно',

    lesson_status_started: 'Идет обучение',

    lesson_type: {
      one: 'Тип урока'
    },

    mark_action_not_rework: 'Переделать нельзя',

    mark_action_required: 'Обязательно выполнить',

    mark_action_rework: 'Нужно переделать',

    mark_action_skip: 'Можно пропустить',

    message: {
      delete_confirm: 'Вы действительно хотите удалить это сообщение?'
    },

    md_editor: {
      label: 'Ответ (текст в формате <a href="https://ru.wikipedia.org/wiki/Markdown" target="_blank" rel="noopener nofollow noreferrer">Markdown</a>):'
    },

    meeting: {
      action: 'Перейти к мероприятию'
    },

    next: {
      one: 'Далее'
    },

    physical_address_person: {
      hint: 'Оставьте поле пустым, если не отличается от адреса регистрации'
    },

    postal_address_of_a_person: {
      hint: 'Оставьте поле пустым, если не отличается от адреса проживания'
    },

    teacher_comments: 'Комментарии преподавателя',

    test_execution_time: 'Этот тест нужно пройти за',

    essay_execution_time: 'Это задание нужно выполнить за',

    documents_library: 'Библиотека документов',

    need_help: 'Обратитесь к администратору.',

    no_answer: 'Ответа почему-то нет.',

    no_answer_option: 'Вариантов ответа почему-то нет.',

    no_task: 'Задания почему-то нет.',

    no_task_text: 'Тест задания почему-то пуст.',

    no_questions: 'Вопросов почему-то нет.',

    no_question_text: 'Текст вопроса почему-то пуст.',

    page_help: 'Помощь по странице',

    show: 'Показать',

    start_survey: 'Начать опрос',

    survey_unavailable: 'Опрос не доступен',

    survey_completed: 'Опрос завершён',

    survey_pending: 'Опрос не начат',

    survey_started: 'Опрос проводится',

    survey_possible_was_only: 'Опрос был доступен',

    survey_possible_will_be_only: 'Опрос будет доступен',

    survey_possible_only: 'Опрос доступен',

    time_zone: 'Часовой пояс',

    version_visually_impaired: 'Версия для слабовидящих',

    work: 'Работа',

    your_answer: 'Ваш ответ',

    your_mark: 'Ваша оценка',

    your_time_expired: 'Ваше время истекло',

    library_catalog: 'Каталог',

    library_any_catalog: 'Любой каталог',

    library_lecture_hall: 'Лекторий',

    library_searching_by_author_title_year_keywords: 'Найти по автору, названию, году, ключевым словам',

    library_search: 'Поиск',

    library_advanced_search: 'Расширенный поиск',

    library_author: 'Автор',

    library_publication_year: 'Год издания',

    library_year: 'Год',

    library_place_of_publication: 'Место издания',

    library_publisher: 'Издательство',

    library_udc: 'УДК',

    library_isbn: 'ISBN',

    library_enter_the_author: 'Введите автора',

    library_enter_the_publication_year: 'Введите год издания',

    library_enter_the_place_of_publication: 'Введите место издания',

    library_enter_the_name_of_publisher: 'Введите название издательства',

    library_materials: 'Материалы',

    library_all_materials: 'Все материалы',

    library_news: 'Новинки',

    library_information: 'Материал',

    library_documents: 'Документы библиотеки',

    library_description: 'Описание',

    library_comment: 'Комментарий',

    library_files: 'Файлы',

    library_accept: 'Принять',

    library_notification: 'Материалы размещены в системе в соответствии с <a href="http://www.consultant.ru/document/cons_doc_LAW_64629/2f837f48edd6ec8026f7b152a88d25f900d16947/" target="_blank" rel="noopener nofollow noreferrer">п. 6 ст. 1275 ГК РФ.</a>',

    library_forbidden_to_copy: 'Запрещается копировать и распространять данные материалы, а также использовать их в коммерческих целях.',

    library_forbidden_to_transfer: 'Запрещается передавать доступ к корпоративной электронной библиотеке третьим лицам. Выберите один вариант ответа.',
  }
})
